import React from "react"
import { InputBase, FormControl } from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { COLORS } from "../../utils/theme"

const useStyles = makeStyles(theme => ({
  rootStyle: {
    width: "100%",
    marginLeft: "0px",
  },
}))

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      width: "100%",
    },
  },
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "transparent",
    border: props =>
      props.error
        ? "1.5px solid #FD6E84 "
        : `1.5px solid ${COLORS.inputBorder}`,
    fontSize: 19,
    marginTop: "10px",
    marginBottom: "10px",
    padding: "18px 12px",
    marginRight: "10px",
    fontFamily: "DM Sans",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["DM Sans"].join(","),
  },
  inviteInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#F2F2F2",
    border: "none",
    fontSize: 16,
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px 12px",
    marginRight: "10px",

    fontFamily: "DM Sans",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["DM Sans"].join(","),
  },
}))(InputBase)

function CustomTextField({
  placeHolder,
  readOnly,
  required,
  type,
  value,
  errors,
  disabled,
  icon,
  inputStyle,
  name,
  onChange,
}) {
  const classes = useStyles()

  return (
    <FormControl className={classes.rootStyle}>
      <BootstrapInput
        error={errors}
        disabled={disabled}
        icon={icon}
        inputStyle={inputStyle}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        required={required}
        type={type}
        value={value}
        id="bootstrap-input"
        placeholder={placeHolder}
      />
    </FormControl>
  )
}

export default CustomTextField
